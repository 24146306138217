import { BadgePlusIcon, LayoutGridIcon, LucideIcon, PencilIcon, VideoIcon } from 'lucide-react';

type Submenu = {
  href: string;
  label: string;
  active?: boolean;
};

type Menu = {
  href: string;
  label: string;
  active: boolean;
  icon: LucideIcon;
  submenus?: Submenu[];
};

type Group = {
  groupLabel: string;
  menus: Menu[];
};

export function getMenuList(pathname: string): Group[] {
  return [
    {
      groupLabel: '',
      menus: [
        {
          href: '/dashboard',
          label: 'Dashboard',
          active: pathname.includes('/dashboard'),
          icon: LayoutGridIcon,
          submenus: [],
        },
      ],
    },
    {
      groupLabel: 'Manage Courses',
      menus: [
        {
          href: '/course/edit',
          label: 'Edit Course',
          active: pathname.includes('/course/edit'),
          icon: PencilIcon,
        },
        {
          href: '/course/new',
          label: 'New Course',
          active: pathname.includes('/course/new'),
          icon: BadgePlusIcon,
        },
      ],
    },
    {
      groupLabel: 'Cloudflare Sync',
      menus: [
        {
          href: '/cloudflare/stream-videos',
          label: 'Cloudflare Stream',
          active: pathname.includes('/cloudflare/stream-videos'),
          icon: VideoIcon,
        },
      ],
    },
  ];
}
